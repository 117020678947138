.App {
  text-align: center;
  background-color: #3c3c3c;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Source Sans Pro', sans-serif;
  overflow: auto;
  height: 100vh;
  color: #f5f5f5;
}
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.Header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 1200px; /* you may adjust this value */
  margin: 5vh auto;
}

.App-logo {
  height: auto;
  width: 60%;
  max-width: 400px;
  pointer-events: none;
  margin: 5vmin;
  transition: transform 0.2s;
}

.App-logo:hover {
  transform: scale(1.05);
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.App p {
  padding: 1vmin;
  font-size: 4vmin;
  font-weight: bold;
  font-style: italic;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.coming-soon {
  font-size: 2vmin;
  font-weight: 700;
  font-style: italic;
  color:#0292b7;
  margin-top: 2vmin;
}

.App-features {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.feature-card {
  margin: 2vmin;
  padding: 2vmin;
  margin-bottom: 10vmin;
  background: linear-gradient(145deg, #4f4f4f, #363636);
  color: #f5f5f5;
  border-radius: 15px;
  text-align: center;
  box-shadow:  20px 20px 10px #2b2b2b, -10px -10px 20px #4f4f4f;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.feature-card:hover {
  transform: translateY(-10px);
}

.feature-card h3 {
  font-size: 2em;
  font-weight: bold;
  margin: 2vmin 0;
}

.feature-card p {
  font-size: 1em;
  margin-bottom: 2vmin;
}

.feature-card button {
  background: #f5f5f5;
  color: #3c3c3c;
  padding: 1vmin 2vmin;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.feature-card button:hover {
  background: #0292b7;
  color: #fff;
}

.image-container {
  display: flex;
  justify-content: center;
  width:90%;
  overflow: hidden;
  margin-bottom: 2vmin;

}

.feature-image {
  width: 90%;
  object-fit: contain;
}

@media (min-width: 768px) {
  .App-header {
    width: 70%;
  }

  .App p {
    font-size: 2em;
  }

  .coming-soon {
    font-size: 1.5em;
  }

  .feature-card {
    width: 60%;
  }
}




@media (max-width: 600px) {
  .image-container {
    padding-top: 160%; /* adjust this value based on how the video is displayed */
  }
}


.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



